@import "../../../styles/base/abstracts";

.hbs-hierarchical-tease {
  @include grid-parent($offset: true);

  .hbs-hierarchical-tease__articles,
  .hbs-hierarchical-tease__content {
    @include grid-child;
    width: auto;
  }

  .hbs-hierarchical-tease__content {
    margin-bottom: $spacing-md;
  }

  .hbs-hierarchical-tease__overline,
  .hbs-hierarchical-tease__subheading {
    @include baseline-text;
  }

  .hbs-hierarchical-tease__overline {
    display: inline-block;
    font-weight: $fw-semibold;
    margin-bottom: $spacing-xs;

    @include mq($bp-desktop-lg) {
      margin-bottom: $spacing-md;
    }
  }

  .hbs-hierarchical-tease__title {
    @include h1;
    margin-top: 0;
    margin-bottom: $spacing-md;

    &.hbs-hierarchical-tease__title--long {
      @include mq($bp-tablet-xl) {
        @include h4;
      }
    }

    &.hbs-hierarchical-tease__title--short {
      @include mq($bp-tablet) {
        @include h1;
      }
    }
  }

  .hbs-hierarchical-tease__subheading {
    max-width: 30em;
  }

  .hbs-hierarchical-tease__cta {
    margin-top: $spacing-sm;
  }

  .hbs-hierarchical-tease__articles {
    @include grid-parent($offset: true);
  }

  .hbs-hierarchical-tease__big-preview {
    @include grid-child;

    .hbs-article-tease__title {
      @include h3;
    }

    @include mq($bp-tablet-xl) {
      width: $col-9;
    }

    @include mq($bp-desktop) {
      width: $col-8;
    }

    @include mq($bp-desktop-lg) {
      width: $col-8;
      margin-right: $col-1;
    }
  }

  .hbs-hierarchical-tease__small-preview {
    @include grid-child;
    margin-top: 2em;

    @include mq($bp-tablet-xl, $bp-desktop) {
      width: $col-7;
      margin-top: 0;

      .hbs-article-tease__image {
        width: nested-cols(2, 7);
      }

      .hbs-article-tease__image + .hbs-article-tease__content {
        width: nested-cols(5, 7);
      }
    }

    @include mq($bp-desktop, $bp-desktop-lg) {
      width: $col-8;
      margin-top: 0;

      .hbs-article-tease__image {
        width: nested-cols(2, 8);
      }

      .hbs-article-tease__image + .hbs-article-tease__content {
        width: nested-cols(6, 8);
      }
    }

    @include mq($bp-desktop-lg) {
      width: $col-7;
      margin-top: 0;

      .hbs-article-tease__image {
        width: nested-cols(2, 7);
      }

      .hbs-article-tease__image + .hbs-article-tease__content {
        width: nested-cols(5, 7);
      }
    }
  }

  .hbs-hierarchical-tease__component-header {
    @include grid-child;
    width: 100%;

    .hbs-component-header__inner {
      border: none;
    }
  }
}
